import { useEffect, useState } from "react";
import { FIELDS, TYPES } from "../../constants/fields";
import classes from "./field.module.css";
import moment from "moment";
import { TimeInputModal } from "../ui/time-input-modal";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../constants/date-time-formats";
import { useAtom } from "jotai";
import { FieldDataAtom } from "../../atoms/auto-screen-atoms";
import { VisitOthersAtom } from "../../atoms/visit-others-atoms";
import { useParams, useSearchParams } from "react-router-dom";


export function Field({
  field,
  autofill,
  timeFormat,
  dateFormat,
  selectOptions,
}) {
  const [searchParams]  = useSearchParams();
  const name = searchParams.get("name"); 
  const lname = searchParams.get("lname");
  const number = searchParams.get("number");
  const bid = searchParams.get("bid");   


  if (field.feature === FIELDS.SIGN.name) return <SignField field={field} />;
  return (
    <>
      <EditView
        field={field}
        defaultValue={{number, name, lname}}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        selectOptions={selectOptions}
      />
    </>
  );
}

function EditView({
  field,
  defaultValue,
  timeFormat,
  dateFormat,
  selectOptions,
}) {
  const [defVisitotVal, setDefVisitOtVal] = useState(null);
  const [fieldData, setFieldData] = useAtom(FieldDataAtom);
  const [visitots] = useAtom(VisitOthersAtom);

  const activeClass =
    field._id in fieldData && fieldData[field._id] !== false
      ? classes.active
      : undefined;

  useEffect(() => {
    init();
  }, []);

  function init() {
    // first time set the field id -> value
    if (field.feature === FIELDS.AUTO_CAL.name) {
      setFieldData((p) => ({
        ...p,
        [field._id]: moment().format(dateFormat || DEFAULT_DATE_FORMAT),
      }));
    } else {
      if (field.type === FIELDS.HIDE.name) return;
      setFieldData((p) => ({ ...p, [field._id]: defaultValue }));
    }
  }

  function onInpValChange(e) {
    const val = e.target.value;
    if (!val) return;
    setFieldData((p) => ({ ...p, [field._id]: val }));
  }

  console.log(field);

  if (field.feature === FIELDS.AUTO_PHONE.name) {
    return (
      <div>
        {fieldData[field._id] && fieldData[field._id] !== false && (
          <p className={classes.field_title}>{field.title}</p>
        )}
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <input
            className={classes.inp_fw}
            disabled={defaultValue.number}
            defaultValue={defaultValue.number}
            onChange={onInpValChange}
          />
        </div>
      </div>
    );
  }


  if (field.feature === FIELDS.AUTO_NAME.name) {
    return (
      <div>
        {fieldData[field._id] && fieldData[field._id] !== false && (
          <p className={classes.field_title}>{field.title}</p>
        )}
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <input
            className={classes.inp_fw}
            disabled={defaultValue.name || defaultValue.lname}
            defaultValue={`${defaultValue.name ? defaultValue.name : ''} ${defaultValue.lname ? defaultValue.lname : ''}`}
            onChange={onInpValChange}
          />
        </div>
      </div>
    );
  }

  if (field.type === FIELDS.HIDE.name) return null;

  if (
    field.feature === FIELDS.TIME_IN.name ||
    field.feature === FIELDS.TIME_30.name ||
    field.feature === FIELDS.TIME_OUT.name
  ) {
    return (
      <TimeIn
        timeFormat={timeFormat || DEFAULT_TIME_FORMAT}
        field={field}
        showIcon
      />
    );
  }

  if (
    field.feature === FIELDS.AUTO_IN.name ||
    field.feature === FIELDS.AUTO_OUT.name
  ) {
    return (
      <TimeIn timeFormat={timeFormat || DEFAULT_TIME_FORMAT} field={field} />
    );
  }

  if (field.feature === FIELDS.SELECT.name) {
    return (
      <div>
        <p className={classes.field_title}>{field.title}</p>
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <select
            className={classes.inp_fw}
            defaultValue={""}
            placeholder={field.title}
            onChange={onInpValChange}
          >
            <option value="" disabled hidden>
              Select
            </option>{" "}
            {/* Placeholder option */}
            {selectOptions.map((op, index) => (
              <option key={index} value={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  if (field.feature === FIELDS.TICK.name) {
    return (
      <div>
        {fieldData[field._id] && fieldData[field._id] !== false && (
          <p className={classes.field_title}>{field.title}</p>
        )}
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <input className={classes.inp_fw} value={field.title} />
          <input type="checkbox" />
        </div>
      </div>
    );
  }

  if (field.feature === FIELDS.CAL.name) {
    return (
      <div>
        <p className={classes.field_title}>{field.title}</p>
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <input
            className={classes.inp_fw}
            type="date"
            onChange={onInpValChange}
          />
        </div>
      </div>
    );
  }

  if (field.feature === FIELDS.TEXT.name) {
    console.log("visitots", visitots);

    useEffect(() => {
      if (Array.isArray(visitots)) {
        for (const obj of visitots) {
          if (field.title === obj?.title) {
            setDefVisitOtVal(obj?.value || null);
            setFieldData((p) => ({ ...p, [field._id]: obj?.value }));
          }
        }
      }
    }, []);

    return (
      <div>
        {fieldData[field._id] && fieldData[field._id] !== false && (
          <p className={classes.field_title}>{field.title}</p>
        )}
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <input
            defaultValue={defVisitotVal}
            className={classes.inp_fw}
            onChange={onInpValChange}
            placeholder={field.title}
          />
        </div>
      </div>
    );
  }

  if (field.type === TYPES.YES.name) return <YesField field={field} />;
  if (field.type === TYPES.NO.name) return <NoField field={field} />;

  if (field.type === TYPES.ADD.name) {
    return (
      <div>
        {fieldData[field._id] && fieldData[field._id] !== false && (
          <p className={classes.field_title}>{field.title}</p>
        )}
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <input className={classes.inp_fw} type="text" />
        </div>
      </div>
    );
  }

  if (field.feature === FIELDS.AUTO_CAL.name) {
    return (
      <div>
        {fieldData[field._id] && fieldData[field._id] !== false && (
          <p className={classes.field_title}>{field.title}</p>
        )}
        <div
          className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        >
          <input
            className={classes.inp_fw}
            value={moment().format(dateFormat || DEFAULT_DATE_FORMAT)}
          />

          <div>
            {/* <img src={FIELDS.TIME_30.img} style={{ marginTop: 5, width: 20 }} /> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className={classes.field_title}>{field.title}</p>
      <div className={`${classes.cont} ${classes.orange_border}`}>
        <input className={classes.inp_fw} value={field.title} />.
      </div>
    </div>
  );
}

// FIELD COMPONENTS

function TimeIn({ timeFormat, field, showIcon }) {
  const [fieldData, setFieldData] = useAtom(FieldDataAtom);

  const activeClass =
    field._id in fieldData && fieldData[field._id] !== false
      ? classes.active
      : undefined;

  const [time, setTime] = useState({
    val: field.feature.includes("AUTO")
      ? moment().format(timeFormat)
      : "Select Time",
    modal: false,
  });

  useEffect(() => {
    if (time.val && field.feature.includes("AUTO")) initTime();
  }, [time]);

  function initTime() {
    // first time set the field id -> value
    setTimeout(() => {
      setFieldData((p) => ({ ...p, [field._id]: time.val }));
    }, 0);
  }

  function handleTimeSave(val) {
    setTime({ val, modal: false });
    setFieldData((p) => ({ ...p, [field._id]: val }));
  }

  const [isClose, setIsClose] = useState(true);

  return (
    <div>
      {fieldData[field._id] && fieldData[field._id] !== false && (
        <p className={classes.field_title}>{field.title}</p>
      )}
      <div
        className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
        // onClick={() => setTime((p) => ({ ...p, modal: !time.modal }))}
      >
        <input
          className={classes.inp_fw}
          disabled={time.val}
          value={time.val}
          onClick={(e) => {
            if (!showIcon) return;
            e.stopPropagation();
            setTime((p) => ({ ...p, modal: !time.modal }));
          }}
        />
        {time.modal && (
          <>
            <div
              className={classes.backdrop}
              onClick={() => setIsClose(!isClose)}
              style={isClose ? null : { display: "none" }}
            ></div>

            {isClose && (
              <TimeInputModal
                defaultValue={moment().format(timeFormat)}
                timeFormat={timeFormat}
                onSave={handleTimeSave}
                onClose={() => setTime((p) => ({ ...p, modal: false }))}
              />
            )}
          </>
        )}

        {showIcon && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setTime((p) => ({ ...p, modal: !time.modal }));
              setIsClose(true);
            }}
            className={classes.edit_btn}
          >
            <img src={FIELDS.TIME_30.img} style={{ marginTop: 5, width: 20 }} />
          </button>
        )}
      </div>
    </div>
  );
}

function YesField({ field }) {
  const [clicked, setClicked] = useState(null);
  const [fieldData, setFieldData] = useAtom(FieldDataAtom);

  const activeClass =
    field._id in fieldData && fieldData[field._id] !== false
      ? classes.active
      : undefined;

  useEffect(() => {
    // initYes();
  }, []);

  function initYes() {
    // first time set the field id -> value
    setTimeout(() => {
      setFieldData((p) => ({ ...p, [field._id]: "YES" }));
    }, 0);
  }

  function handleClick(val) {
    setClicked(val);
    setFieldData((p) => ({ ...p, [field._id]: val }));
  }

  return (
    <div>
      <p className={`${classes.yesno} ${activeClass}`}> {field.title} </p>

      <div
        className={`${classes.flex_row} ${classes.flex_start}`}
        style={{ marginBottom: 10 }}
      >
        <button
          className={`${classes.sm_btns} ${classes.orange_btn} ${
            clicked === "YES"
              ? classes.active_orng
              : clicked === "NO"
                ? classes.inactive
                : null
          }`}
          onClick={() => handleClick("YES")}
        >
          {/* <img src={TYPES.YES.img} style={{ width: 15, height: 15 }} /> */}
          <p>Yes</p>
        </button>

        <button
          className={`${classes.sm_btns} ${
            clicked === "NO"
              ? classes.active_grey
              : clicked === "YES"
                ? classes.inactive
                : null
          }`}
          onClick={() => handleClick("NO")}
        >
          {/* <img src={TYPES.NO.img} style={{ width: 15, height: 15 }} /> */}
          <p>No</p>
        </button>
      </div>
    </div>
  );
}

function NoField({ field }) {
  const [clicked, setClicked] = useState(null);
  const [fieldData, setFieldData] = useAtom(FieldDataAtom);

  const activeClass =
    field._id in fieldData && fieldData[field._id] !== false
      ? classes.active
      : undefined;

  useEffect(() => {
    // initNo();
  }, []);

  function initNo() {
    // first time set the field id -> value
    setFieldData((p) => ({ ...p, [field._id]: "NO" }));
  }

  function handleClick(val) {
    setClicked(val);
    setFieldData((p) => ({ ...p, [field._id]: val }));
  }

  return (
    <div>
      <div
        className={`${classes.cont} ${classes.orange_border} ${activeClass}`}
      >
        <input className={classes.inp_fw} value={field.title} />
      </div>

      <div
        className={`${classes.flex_row} ${classes.flex_start}`}
        style={{ marginBottom: 10 }}
      >
        <button
          className={`${classes.sm_btns}  ${
            clicked === "YES" ? classes.active_grey : null
          }`}
          onClick={() => handleClick("YES")}
        >
          YES
        </button>

        <button
          className={`${classes.sm_btns} ${classes.orange_btn} ${
            clicked === "NO" ? classes.active_orng : null
          }`}
          onClick={() => handleClick("NO")}
        >
          NO
        </button>
      </div>
    </div>
  );
}

function SignField({ field }) {
  const [, setFieldData] = useAtom(FieldDataAtom);

  useEffect(() => {
    setTimeout(() => {
      setFieldData((p) => ({ ...p, [field._id]: FIELDS.SIGN.name }));
    }, 0);
  }, []);

  return null;
}
