import classes from "./checkbox.module.css";

export function Checkbox({ label, checked, onClick }) {
  return (
    <div className={classes.aggreement_box}>
      <input
        onClick={onClick}
        type="checkbox"
        id="agreement"
        name="agreement"
        checked={checked}
      />
      {label && (
        <label htmlFor="agreement" className={classes.check_label}>
          {label}
        </label>
      )}
    </div>
  );
}
