// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-paid_main__BB9sz {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 350px) {
  .login-paid_main__BB9sz {
    position: absolute;
    top: 50%;
    padding-top: 40px;

    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 100%;
    max-width: 350px;
    justify-content: center;
    align-items: center;
  
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/login-paid.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;IAClB,QAAQ;IACR,iBAAiB;;IAEjB,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;;EAErB;AACF","sourcesContent":[".main {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media only screen and (max-width: 350px) {\n  .main {\n    position: absolute;\n    top: 50%;\n    padding-top: 40px;\n\n    left: 50%;\n    transform: translate(-50%, -50%);\n    display: flex;\n    width: 100%;\n    max-width: 350px;\n    justify-content: center;\n    align-items: center;\n  \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `login-paid_main__BB9sz`
};
export default ___CSS_LOADER_EXPORT___;
