import QRCode from "react-qr-code";
import { getBaseUrl, getPwaUrl } from "../../constants/base-url";
import { QR } from "./qr";
import classes from "./share-panel.module.css";
import { useRef, useState } from "react";
import { useClipboard } from "../../hooks/use-clipboard";
import { errorToast, successToast } from "../../utils/notifications";
import { isValidEmail } from "../../utils/validation";
import axios from "axios";
import { HTTP_STATUS } from "../../constants/http-status";
import { getMsgFromAxiosErr } from "../../utils/get-axios-err-msg";
import { useNavigate } from "react-router-dom";

export function SharePanel({ business }) {
  const [showQr, setShowQr] = useState(false);
  const pwaLoginLink = `${getPwaUrl()}/${business?._id}`;
  const clipboard = useClipboard({ timeout: 1000 });
  const [sending, setSending] = useState(false);

  const navigate = useNavigate();
  const emailRef = useRef(null);

  function copyLink() {
    clipboard.copy(pwaLoginLink);
    successToast("Link copied to clipboard");
  }

  if (showQr) {
    return <QR business={business} onClose={() => setShowQr(false)} />;
  }

  async function sendLink() {
    try {
      const email = emailRef.current?.value;
      if (!email) return errorToast("Email is required");
      if (isValidEmail(email) === false) {
        return errorToast("Please enter a valid email");
      }

      setSending(true);
      const res = await axios.post(`${getBaseUrl()}/pwa/send-link`, {
        email,
        businessId: business._id,
      });
      setSending(false);

      if (res.status !== HTTP_STATUS.OK) {
        return errorToast(res.data?.msg || "Something went wrong");
      }

      emailRef.current.value = null;
      successToast("Link sent successfully");
    } catch (error) {
      setSending(false);
      const msg = getMsgFromAxiosErr(error);
      errorToast(msg);
    }
  }

  return (
    <div className={classes.cont}>
      <h4 className={classes.email}>Email Address</h4>
      <input
        placeholder="Email Address"
        className={classes.email_input}
        ref={emailRef}
      />
      <button
        className={classes.send_btn}
        onClick={sendLink}
        disabled={sending}
      >
        Send
      </button>
      <div className={classes.flex_row}>
        <button className={classes.icon_btn} onClick={copyLink}>
          <span>
            {clipboard.copied ? (
              <img src="/share/check.svg" className={classes.icon} />
            ) : (
              <img src="/share/link-icon.svg" className={classes.icon} />
            )}
          </span>{" "}
          {clipboard.copied ? "Copied" : "Copy Link"}
        </button>
        <button
          className={`${classes.icon_btn} ${classes.qr_btn}`}
          onClick={setShowQr}
        >
          <span>
            <img src="/share/qr-icon.svg" className={classes.icon} />
          </span>{" "}
          QR Code
        </button>
      </div>

      <div className={classes.qr_cont}>
        <QRCode
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={pwaLoginLink}
        />
      </div>

      

      {/* Bottom Two Buttons */}
      <div className={classes.log}>
        <button onClick={() => navigate("/")}>Keypad</button>
        <button onClick={() => navigate("/waiting-list")}>Waiting List</button>
      </div>
    </div>
  );
}
