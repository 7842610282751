import { useEffect, useState } from "react";
import classes from "./name-pad.module.css";
import { getBaseUrl } from "../../constants/base-url";
import axios from "axios";
import { HTTP_STATUS } from "../../constants/http-status";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../utils/notifications";
import { useFetchPrevVisit } from "../../hooks/use-fetch-visit";
import { getMsgFromAxiosErr } from "../../utils/get-axios-err-msg";
import { SignForm } from "../modal/sign-form";
import { removeFileExtension } from "../../utils/conversation";
import { useAtom } from "jotai";
import { VisitOthersAtom } from "../../atoms/visit-others-atoms";
import { IoArrowBackOutline } from "react-icons/io5";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import Picker from "react-scrollable-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export function NamePad({ phone, business, onCancel }) {
  const [selectedNumber, setSelectedNumber] = useState(1);
  const { data: visit } = useFetchPrevVisit(phone, business);

  const businessType = "restaurant";

  const defaultForm = { fname: null, lname: null, mname: null, party: 1 };
  const [checked, setChecked] = useState(false);
  const [signForm, setSignForm] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [signedImg, setSignedImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setVisitOts] = useAtom(VisitOthersAtom);
  const [isSaving, setIsSaving] = useState(false);

  const handleScroll = (direction) => {
    if (direction === "up") {
      setSelectedNumber((prev) => prev + 1);
    } else {
      setSelectedNumber((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (visit) {
      if (visit.name) setForm((p) => ({ ...p, fname: visit.name }));
      if (visit.midname) setForm((p) => ({ ...p, mname: visit.midname }));
      if (visit.lastname) setForm((p) => ({ ...p, lname: visit.lastname }));

      if (visit.others) {
        setVisitOts(JSON.parse(visit.others));
      }

      // if (visit.others)
    }
  }, [visit]);

  useEffect(() => {
    setForm((p) => ({ ...p, party: selectedNumber }));
  }, [selectedNumber]);

  const navigate = useNavigate();

  // async function signinUser() {
  //   try {
  //     const businessId = business._id;
  //     if (!businessId) {
  //       return errorToast("No business found kindly open the full link again");
  //     }

  //     const newForm = {
  //       businessId,
  //       number: phone,
  //       name: form.fname,
  //       lastname: form.lname,
  //       midname: form.mname,
  //       date: new Date().toString(),
  //     };

  //     if (!newForm.number) throw new Error("There should be a number");
  //     if (!newForm.name || !newForm.lastname) {
  //       return errorToast("Firstname and Lastname is required");
  //     }

  //     const formData = new FormData();
  //     Object.keys(newForm).forEach((key) => formData.append(key, newForm[key]));

  //     if (signedImg) formData.append("sign", signedImg);

  //     setLoading(true);
  //     const res = await axios.post(`${getBaseUrl()}/pwa/login`, formData);
  //     setLoading(false);
  //     setForm(defaultForm);

  //     if (res.status !== HTTP_STATUS.CREATED) {
  //       return errorToast("Something went wrong");
  //     }

  //     const signedUrl = res.data?.sign?.url;
  //     const preview = res?.data?.previewPdf;

  //     if (preview === true && signedUrl) {
  //       // navigate to send attach file
  //       let linkToSendFile = `/send-file`;
  //       if (res.data?.sign?.url) {
  //         linkToSendFile += `?sign=${signedUrl}`;
  //       }

  //       let fname = newForm.name;
  //       let mname = newForm.midname;
  //       let lname = newForm.lastname;

  //       if (fname) linkToSendFile += `&fname=${fname}`;
  //       if (mname) linkToSendFile += `&mname=${mname}`;
  //       if (lname) linkToSendFile += `&lname=${lname}`;

  //       navigate(linkToSendFile);
  //     } else {
  //       // navigate to waitingList with the full name
  //       let name = newForm.name + " ";
  //       if (newForm.midname) name += `${newForm.midname} `;
  //       if (newForm.lastname) name += `${newForm.lastname} `;
  //       const linkToWaitingList = `/waiting-list?name=${name}`;
  //       navigate(linkToWaitingList);
  //     }
  //   } catch (error) {
  //     console.log("handleSaveClick() :", error);
  //     setLoading(false);
  //     const msg = getMsgFromAxiosErr(error);
  //     errorToast(msg);
  //   }
  // }

  // function navigateToAuto() {
  //   const businessId = business._id;
  //   if (!businessId) {
  //     return errorToast("No business found kindly open the full link again");
  //   }

  //   const newForm = {
  //     businessId,
  //     number: phone,
  //     name: form.fname,
  //     lastname: form.lname,
  //     midname: form.mname,
  //   };

  //   if (!newForm.number) throw new Error("There should be a number");
  //   if (!newForm.name || !newForm.lastname) {
  //     return errorToast("Firstname and Lastname is required");
  //   }

  //   let linkToAuto = `/auto`;

  //   if (newForm.name) linkToAuto += `?name=${newForm.name}`;
  //   if (newForm.midname) linkToAuto += `&mname=${newForm.midname}`;
  //   if (newForm.lastname) linkToAuto += `&lname=${newForm.lastname}`;
  //   if (newForm.number) linkToAuto += `&number=${newForm.number}`;
  //   if (newForm.businessId) linkToAuto += `&bid=${newForm.businessId}`;

  //   localStorage.setItem("number", newForm.number);
  //   navigate(linkToAuto);
  // }

  // function handleOnSignSave(blob) {
  //   setSignedImg(blob);
  //   setSignForm(false);
  //   setChecked(true);
  // }

  // function routeSaveClick() {
  //   if (business?.fields?.length) {
  //     // fields exist: user uploaded mapping
  //     navigateToAuto();
  //   } else {
  //     signinUser();
  //   }
  // }

  function restaurantNavigateToAuto() {
    const businessId = business._id;
    if (!businessId) {
      return errorToast("No business found kindly open the full link again");
    }

    const newForm = {
      businessId,
      number: phone,
      name: form.fname,
      lastname: form.lname,
      midname: form.mname,
    };

    if (!newForm.number) throw new Error("There should be a number");
    if (!newForm.name ) {
      return errorToast("Firstname and Lastname is required");
    }

    let linkToAuto = `/auto`;

    if (newForm.name) linkToAuto += `?name=${newForm.name}`;
    if (newForm.midname) linkToAuto += `&mname=${newForm.midname}`;
    if (newForm.lastname) linkToAuto += `&lname=${newForm.lastname}`;
    if (newForm.number) linkToAuto += `&number=${newForm.number}`;
    if (newForm.businessId) linkToAuto += `&bid=${newForm.businessId}`;

    localStorage.setItem("number", newForm.number);
    navigate(linkToAuto);
  }

  async function restaurantSignUser() {
    try {
      const businessId = business._id;
      if (!businessId) {
        return errorToast("No business found kindly open the full link again");
      }

      const newForm = {
        businessId,
        number: phone,
        name: form.fname,
        party: form.party,
        businessType: businessType,
      };

      if (!newForm.number) throw new Error("There should be a number");
      if (!newForm.name) {
        return errorToast("Name is required");
      }

      const formData = new FormData();
      Object.keys(newForm).forEach((key) => formData.append(key, newForm[key]));

      setLoading(true);

      const res = await axios.post(`${getBaseUrl()}/pwa/login`, formData);

      console.log("res", res);
      setLoading(false);
      setForm(defaultForm);

      if (res.status !== HTTP_STATUS.CREATED) {
        return errorToast("Something went wrong");
      }

      const signedUrl = res.data?.sign?.url;
      const preview = res?.data?.previewPdf;

      if (preview === true && signedUrl) {
        // navigate to send attach file
        let linkToSendFile = `/send-file`;
        if (res.data?.sign?.url) {
          linkToSendFile += `?sign=${signedUrl}`;
        }

        let name = newForm.name;

        if (name) linkToSendFile += `&name=${name}`;

        navigate(linkToSendFile);
      } else {
        // navigate to waitingList with the full name
        let name = newForm.name;
        const linkToWaitingList = `/waiting-list?name=${name}`;
        navigate(linkToWaitingList);
      }
    } catch (error) {
      console.log("handleSaveClick() :", error);
      setLoading(false);
      const msg = getMsgFromAxiosErr(error);
      errorToast(msg);
    } finally {
      setIsSaving(false);
    }
  }

  function restaurantRouteSaveClick() {
    if (business?.fields?.length) {
      // fields exist: user uploaded mapping
      restaurantNavigateToAuto();
    } else {
      restaurantSignUser();
    }
  }

  const numberOptions = Array.from({ length: 100 }, (_, i) => ({
    value: i,
    label: i.toString(),
  }));

  // const handleSaveClick = () => {
  //   if (isSaving) return;
  //   setIsSaving(true);
  //   const numberValue = selectedNumber;
  //   console.log("Selected number:", numberValue);
  //   if (businessType === "restaurant") {
  //     restaurantRouteSaveClick();
  //   } else {
  //     console.log("restaurantNavigateToAuto");
  //     routeSaveClick();
  //   }
  // };

  // Split business name into words
  const words = business.name.split(" ");
  const firstWord = words.shift(); // Remove the first word
  const restOfWords = words.join(" "); // Join the remaining words
  const currentDate = new Date();
  const dayNumber = currentDate.getDate();
  const daySuffix = getDaySuffix(dayNumber);
  const month = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(currentDate);
  const day = currentDate.toLocaleString("default", { weekday: "long" }); // E.g., "Tue"

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) return "th";
    const lastDigit = day % 10;
    if (lastDigit === 1) return "st";
    if (lastDigit === 2) return "nd";
    if (lastDigit === 3) return "rd";
    return "th";
  }
  return (
    <div className="no-scroll">
      <div className={classes.page}>
        <div className={classes.pagescroll}>
          <div className={classes.header}>
            <div className={classes.containerBoxData}>
              <div className={classes.boxData}>
                {/*  12/04/2024 */}
                <div className={classes.month}>{month}</div>
                {/*  Day */}
                <div className={classes.day}>{day}</div>
              </div>
            </div>
            <div>
              <div className={classes.businessName}>
                <span className={classes.firstWord}>{firstWord}</span>
                <span className={classes.restOfWords}>{restOfWords}</span>
              </div>
            </div>
          </div>
          <div className={classes.container}>
            {/* Header */}

            <div className={classes.content}>
              {/* Content */}
              {businessType != "restaurant" && (
                <div className={classes.content}>
                  <div>
                    <p className={classes.label}>First Name</p>
                    <input
                      value={form.fname}
                      className={classes.inp}
                      onChange={(e) =>
                        setForm({ ...form, fname: e.target.value })
                      }
                      // placeholder="First Name"
                    />
                  </div>
                  <div>
                    <p className={classes.label}>Middle Name or Initial</p>
                    <div className={classes.inp_cont}>
                      <input
                        value={form.mname}
                        className={classes.inp}
                        onChange={(e) =>
                          setForm({ ...form, mname: e.target.value })
                        }
                        // placeholder="Middle Name or Initial (Optional)"
                      />
                      <span className={classes.optional_span}>Optional</span>
                    </div>
                  </div>
                  <div>
                    <p className={classes.label}>Last Name</p>
                    <input
                      value={form.lname}
                      className={classes.inp}
                      onChange={(e) =>
                        setForm({ ...form, lname: e.target.value })
                      }
                      // placeholder="Last Name"
                    />
                  </div>

                  {business?.fields?.length === 0 && business?.file?.name && businessType !== "restaurant" (
                    <div className={classes.aggreement_box}>
                      <input
                        type="checkbox"
                        id="agreement"
                        name="agreement"
                        checked={checked}
                        onClick={() => setSignForm(!signForm)}
                      />
                      <label
                        htmlFor="agreement"
                        className={classes.check_label}
                      >
                        I agree to apply my dname, initials, and signature where
                        applicable on{" "}
                        {removeFileExtension(business?.file?.name)}
                      </label>
                    </div>
                  )}

                  <div className={classes.next_btn}>
                    <button onClick={routeSaveClick}>
                      {business?.fields?.length ? "Next" : "Sign In"}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>
              )}

              {businessType === "restaurant" && (
                <div className={classes.content}>
                  {/* <div>
              <p className={classes.label}>First Name</p>
              <input
                value={form.fname}
                className={classes.inp}
                onChange={(e) => setForm({ ...form, fname: e.target.value })}
                // placeholder="First Name"
              />
            </div>
            <div>
              <p className={classes.label}>Middle Name or Initial</p>
              <div className={classes.inp_cont}>
                <input
                  value={form.mname}
                  className={classes.inp}
                  onChange={(e) => setForm({ ...form, mname: e.target.value })}
                  // placeholder="Middle Name or Initial (Optional)"
                />
                <span className={classes.optional_span}>Optional</span>
              </div>
            </div>
            <div>
              <p className={classes.label}>Last Name</p>
              <input
                value={form.lname}
                className={classes.inp}
                onChange={(e) => setForm({ ...form, lname: e.target.value })}
                // placeholder="Last Name"
              />
            </div> */}

                  <div className={classes.inp_content}>
                    <p>What's your name?</p>
                    {/* input */}
                    <input
                      value={form.fname}
                      placeholder="your name"
                      className={classes.inp_resturant}
                      onChange={(e) =>
                        setForm({ ...form, fname: e.target.value })
                      }
                    />
                  </div>

                  {/* <div className={classes.inp_content}>

              <p>How many are in your party?</p>

              <button
                className={classes.decrement_btn}
                onClick={() => setForm({ ...form, party: Math.max(1, form.party - 1) })}
              >
                <SlArrowLeft color="black" />
              </button>

              <div className={classes.party_input}>

                <input
                  value={form.party}
                  type="number"
                  min={1}
                  className={classes.inp_resturant_party}
                  onChange={(e) => setForm({ ...form, party: Math.max(1, parseInt(e.target.value) || 1) })}
                />

              </div>

              <button
                className={classes.increment_btn}
                onClick={() => setForm({ ...form, party: form.party + 1 })}
              >
                <SlArrowRight color="black" />
              </button>
            </div> */}

                  <div className={classes.inp_content}>
                    <p>How many are in your party?</p>

                    <div className={classes.party_input}>
                      <button
                        className={classes.decrement_btn}
                        onClick={() =>
                          setForm({
                            ...form,
                            party: Math.max(1, form.party - 1),
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>

                      <input
                        value={form.party}
                        type="tel" // Change type to 'tel' to show the phone numpad keyboard
                        min={0}
                        max={99} // Set the maximum value to 99
                        readOnly
                        className={classes.inp_resturant_party}
                        onChange={(e) => {
                          const value = Math.max(
                            0,
                            Math.min(99, parseInt(e.target.value) || 0),
                          ); // Ensure value is between 1 and 99
                          setForm({ ...form, party: value });
                        }}
                      />

                      <button
                        className={classes.increment_btn}
                        onClick={() =>
                          setForm({ ...form, party: form.party + 1 })
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className={classes.footer}>
                <div className={classes.footer_content}>
                  {/* Cross Btn */}
                  <div className={classes.cross} onClick={onCancel}>
                    <button>
                      <IoArrowBackOutline size={"1.2em"} />
                    </button>
                  </div>

                  <div className={classes.next_btn} onClick={restaurantSignUser}>
                    <p>{business?.fields?.length ? "Next" : "Sign In "}</p>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>

                {/* {signForm && (
                  <SignForm
                    onClose={() => setSignForm(false)}
                    onSave={handleOnSignSave}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
