import { useEffect, useState } from "react";
import classes from "./name-pad.module.css";
import { getBaseUrl } from "../../../constants/base-url";
import axios from "axios";
import { HTTP_STATUS } from "../../../constants/http-status";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../utils/notifications";
import { useFetchPrevVisit } from "../../../hooks/use-fetch-visit";
import { getMsgFromAxiosErr } from "../../../utils/get-axios-err-msg";
import { SignForm } from "../../modal/sign-form";
import { removeFileExtension } from "../../../utils/conversation";
import { useAtom } from "jotai";
import { VisitOthersAtom } from "../../../atoms/visit-others-atoms";
import { format } from 'date-fns';

export function NamePadOriginal({ phone, business, onCancel }) {
  const { data: visit } = useFetchPrevVisit(phone, business);

  const defaultForm = { fname: null, lname: null, mname: null };
  const [checked, setChecked] = useState(false);
  const [signForm, setSignForm] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [signedImg, setSignedImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setVisitOts] = useAtom(VisitOthersAtom);

  useEffect(() => {
    if (visit) {
      if (visit.name) setForm((p) => ({ ...p, fname: visit.name }));
      if (visit.midname) setForm((p) => ({ ...p, mname: visit.midname }));
      if (visit.lastname) setForm((p) => ({ ...p, lname: visit.lastname }));

      if (visit.others) {
        setVisitOts(JSON.parse(visit.others));
      }

      // if (visit.others)
    }
  }, [visit]);

  const navigate = useNavigate();

  async function signinUser() {
    try {
      const businessId = business._id;
      if (!businessId) {
        return errorToast("No business found kindly open the full link again");
      }

      let dateFormat = business.dateFormat === "DD/MM/YYYY" ? "dd/MM/yyyy" : business.dateFormat === "YYYY/MM/DD" ? "yyyy/MM/dd" :business.dateFormat === "MM-DD-YYYY" ? "MM/dd/yyyy" :business.dateFormat === "YYYY-MM-DD" ? "yyyy/MM/dd" :business.dateFormat === "MM.DD.YYYY" ? "MM/dd/yyyy" :business.dateFormat === "YYYY.MM.DD" ? "yyyy/MM/dd" :business.dateFormat === "DD.MM.YYYY" ? "dd/MM/yyyy" : "MM/dd/yyyy"

      const newForm = {
        businessId,
        number: phone,
        name: form.fname,
        lastname: form.lname,
        midname: form.mname,
        date: format(new Date(), dateFormat),
      };

      if (!newForm.number) throw new Error("There should be a number");
      if (!newForm.name || !newForm.lastname) {
        return errorToast("Firstname and Lastname is required");
      }

      const formData = new FormData();
      Object.keys(newForm).forEach((key) => formData.append(key, newForm[key]));

      if (signedImg) formData.append("sign", signedImg);

      setLoading(true);
      const res = await axios.post(`${getBaseUrl()}/pwa/login`, formData);
      setLoading(false);
      setForm(defaultForm);

      if (res.status !== HTTP_STATUS.CREATED) {
        return errorToast("Something went wrong");
      }

      const signedUrl = res.data?.sign?.url;
      const preview = res?.data?.previewPdf;

      if (preview === true && signedUrl) {
        // navigate to send attach file
        let linkToSendFile = `/send-file`;
        if (res.data?.sign?.url) {
          linkToSendFile += `?sign=${signedUrl}`;
        }

        let fname = newForm.name;
        let mname = newForm.midname;
        let lname = newForm.lastname;

        if (fname) linkToSendFile += `&fname=${fname}`;
        if (mname) linkToSendFile += `&mname=${mname}`;
        if (lname) linkToSendFile += `&lname=${lname}`;

        navigate(linkToSendFile);
      } else {
        // navigate to waitingList with the full name
        let name = newForm.name + " ";
        if (newForm.midname) name += `${newForm.midname} `;
        if (newForm.lastname) name += `${newForm.lastname} `;
        const linkToWaitingList = `/waiting-list?name=${name}`;
        if(business.hideWaitingList) {
          successToast("You have been added to the waiting list");
          navigate(`/`);
        } else {
          navigate(linkToWaitingList);
        }
      }
    } catch (error) {
      console.log("handleSaveClick() :", error);
      setLoading(false);
      const msg = getMsgFromAxiosErr(error);
      errorToast(msg);
    }
  }

  function navigateToAuto() {
    const businessId = business._id;
    if (!businessId) {
      return errorToast("No business found kindly open the full link again");
    }

    const newForm = {
      businessId,
      number: phone,
      name: form.fname,
      lastname: form.lname,
      midname: form.mname,
    };

    if (!newForm.number) throw new Error("There should be a number");
    if (!newForm.name || !newForm.lastname) {
      return errorToast("Firstname and Lastname is required");
    }

    let linkToAuto = `/auto`;

    if (newForm.name) linkToAuto += `?name=${newForm.name}`;
    if (newForm.midname) linkToAuto += `&mname=${newForm.midname}`;
    if (newForm.lastname) linkToAuto += `&lname=${newForm.lastname}`;
    if (newForm.number) linkToAuto += `&number=${newForm.number}`;
    if (newForm.businessId) linkToAuto += `&bid=${newForm.businessId}`;

    localStorage.setItem("number", newForm.number);
    navigate(linkToAuto);
  }

  function handleOnSignSave(blob) {
    setSignedImg(blob);
    setSignForm(false);
    setChecked(true);
  }

  function routeSaveClick() {
    if (business?.fields?.length) {
      // fields exist: user uploaded mapping
      navigateToAuto();
    } else {
      signinUser();
    }
  }

  if (loading) return <p>Loading...</p>;

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {/* Cross Btn */}
        <div className={classes.cross} onClick={onCancel}>
          <button>
            <img style={{ height: "1rem", width: "1rem" }} src="/x.svg" />
          </button>
        </div>

        {/* Header */}
        <div className={classes.header}>
          <p>{`{${phone}}`}</p>
        </div>

        {/* Content */}
        <div className={classes.content}>
          <div>
            <p className={classes.label}>First Name</p>
            <input
              value={form.fname}
              className={classes.inp}
              onChange={(e) => setForm({ ...form, fname: e.target.value })}
              // placeholder="First Name"
            />
          </div>
          <div>
            <p className={classes.label}>Middle Name or Initial</p>
            <div className={classes.inp_cont}>
              <input
                value={form.mname}
                className={classes.inp}
                onChange={(e) => setForm({ ...form, mname: e.target.value })}
                // placeholder="Middle Name or Initial (Optional)"
              />
              <span className={classes.optional_span}>Optional</span>
            </div>
          </div>
          <div>
            <p className={classes.label}>Last Name</p>
            <input
              value={form.lname}
              className={classes.inp}
              onChange={(e) => setForm({ ...form, lname: e.target.value })}
              // placeholder="Last Name"
            />
          </div>
          {!business?.fields?.length && business?.file?.url && (

          <div className={classes.aggreement_box}>
            <input
              type="checkbox"
              id="agreement"
              name="agreement"
              checked={checked}
              onClick={() => setSignForm(!signForm)}
            />
            <label htmlFor="agreement" className={classes.check_label}>
              I agree to apply my name, initials, and signature where applicable on{" "}
              <a
                href={business?.file?.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.file_link}
              >
                {removeFileExtension(business?.file?.name)}
              </a>
            </label>
          </div>
          )}
          <button className={classes.btn} onClick={routeSaveClick}>
            {business?.fields?.length ? "Next" : "Sign In"}
          </button>
        </div>

        {signForm && (
          <SignForm
            onClose={() => setSignForm(false)}
            onSave={handleOnSignSave}
          />
        )}

      </div>
    </div>
  );
}
