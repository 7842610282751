import { useState, useRef } from "react";
import classes from "./dial-pad.module.css";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../../utils/notifications";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoTimerOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export function DialPad({ business, error }) {
  const [val, setVal] = useState(Array(10).fill('')); // Initially 10 empty strings (one for each input)
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  function handleChange(index, event) {
    const newValue = event.target.value;
    const newVal = [...val];
    newVal[index] = newValue;
    setVal(newVal);

    // Move to the next input field automatically if a digit is entered
    if (newValue && index < 9) {
      inputRefs.current[index + 1].focus();
    }

    // Enable "Next" button when all 10 fields are filled
    if (newVal.join('').length === 10) setDisabled(false);
    else setDisabled(true);
  }

  function handleBtnClick(num) {
    const numToStr = num.toString();
    const newVal = [...val];

    // Find the first empty index and update it with the new number
    const firstEmptyIndex = newVal.findIndex(digit => digit === '');
    
    if (firstEmptyIndex === -1) return; // If there's no empty field, do nothing

    // Update the first empty input field with the new digit
    newVal[firstEmptyIndex] = numToStr;

    // Check if the length of the value (i.e., the number of digits) is 10
    if (newVal.join('').length >= 10) setDisabled(false);
    else setDisabled(true);

    setVal(newVal);
  }

  function handleClearClick() {
    setDisabled(true);
    setVal(Array(10).fill('')); // Reset all inputs to empty
  }
  function handleBackspace() {
    // Find the last non-empty digit in the array
    const newVal = [...val];
    
    // Find the index of the last non-empty digit
    const lastNonEmptyIndex = newVal.findIndex(digit => digit === '');
    const deleteIndex = lastNonEmptyIndex === -1 ? 9 : lastNonEmptyIndex - 1;
    
    // Remove the last non-empty digit
    newVal[deleteIndex] = '';
  
    // Focus the last non-empty input if there is one
    if (deleteIndex >= 0) {
      inputRefs.current[deleteIndex].focus();
    }
  
    // Disable "Next" button if there are less than 10 digits
    if (newVal.join('').length < 10) setDisabled(true);
    
    setVal(newVal);
  }
  

  function handleKeyDown(event, index) {
    const keyCode = event.which || event.keyCode;
    // Allow only numbers (0-9) and backspace (8)
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 8) {
      if (keyCode === 8) {
        handleBackspace(index); // Handle backspace
      } else {
        handleChange(index, event); // Handle digit input
      }
    } else {
      event.preventDefault();
    }
  }

  function handleNextClick() {
    if (val.join('').length !== 10) return errorToast("Invalid Number");
    navigate(`/name-form/${val.join('')}`);
  }

  if (error) return <p>{error}</p>;
  if (!business || !business.name) {
    return <p>No Business Found, Kindly Open the full Link again</p>;
  }

  // Split business name into words
  const words = business.name.split(" ");
  const firstWord = words.shift(); // Remove the first word
  const restOfWords = words.join(" "); // Join the remaining words
  const image = business?.businessProfile;
  const currentDate = new Date();
  const month = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(currentDate);
  const day = currentDate.toLocaleString("default", { weekday: "long" }); // E.g., "Tue"

  return (
    <div className="no-scroll">
      <div className={classes.page}>
        <div className={classes.pagescroll}>
          <div className={classes.containerBoxData}>
            <div className={classes.boxData}>
              <div className={classes.month}>{month}</div>
              <div className={classes.day}>{day}</div>
            </div>
          </div>
          <div className={classes.header}>
            <div className={classes.imageContainer}>
              {image && <img className={classes.imageUser} src={image} />}
            </div>
            <div className={classes.businessName}>
              <span className={classes.firstWord}>{firstWord}</span>
              <span className={classes.restOfWords}>{restOfWords}</span>
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.content}>
              <div className={classes.log}>
                <div className={classes.input_sec}>
                <div className={classes.dial_nums_input}>
                    {/* Render 10 input fields, each for a single digit */}
                    {Array.from({ length: 10 }).map((_, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className={`${classes.num_input} ${val[index] !== '' ? classes.notEmpty : ''}`}
                        value={val[index]}
                        onChange={(e) => handleChange(index, e)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        maxLength={1}
                      />
                    ))}
                  </div>
                </div>

                <div className={classes.dial_nums}>
                  <div className={classes.row}>
                    <Btn onClick={handleBtnClick}>1</Btn>
                    <Btn onClick={handleBtnClick}>2</Btn>
                    <Btn onClick={handleBtnClick}>3</Btn>
                  </div>
                  <div className={classes.row}>
                    <Btn onClick={handleBtnClick}>4</Btn>
                    <Btn onClick={handleBtnClick}>5</Btn>
                    <Btn onClick={handleBtnClick}>6</Btn>
                  </div>
                  <div className={classes.row}>
                    <Btn onClick={handleBtnClick}>7</Btn>
                    <Btn onClick={handleBtnClick}>8</Btn>
                    <Btn onClick={handleBtnClick}>9</Btn>
                  </div>
                  <div className={classes.row}>
                    <Btn
                      onClick={() => handleBackspace(val.findIndex((digit) => digit !== ''))}
                      id="btnBack"
                      className={classes.btnBack}
                    >
                      Back
                    </Btn>
                    <Btn onClick={handleBtnClick}>0</Btn>
                    <Btn
                      onClick={handleClearClick}
                      id="btnClear"
                      className={classes.btnClear}
                    >
                      Clear
                    </Btn>
                  </div>
                </div>

                <div className={classes.footer1}>
                  <div className={classes.footer}>
                    <button
                      onClick={() => navigate("/share")}
                      className={classes.footerIcon}
                    >
                      <IoShareSocialOutline size={"2rem"} color="#ff8120" />
                    </button>
                    <button
                      className={classes.next_btn}
                      disabled={disabled}
                      onClick={handleNextClick}
                    >
                      Next <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                    {!business.hideWaitingList && (
                      <button
                        onClick={() => navigate("/waiting-list")}
                        className={classes.footerIcon}
                      >
                        <IoTimerOutline size={"2rem"} color="#ff8120" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Btn({ children, onClick }) {
  function handleClick() {
    onClick(children);
  }

  return (
    <button onClick={handleClick} className={classes.dial_btn}>
      {children}
    </button>
  );
}
