import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classes from "./waiting-list.module.css";
import moment from "moment";
import { useFetchWaitingList } from "../hooks/use-fetch-waitinglist";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../constants/date-time-formats";

export function WaitingListPage() {
  const navigate = useNavigate();

  return (
    <main className={classes.main}>
      <div className={classes.container}>
        <WaitingList onTimerEnd={() => navigate("/waiting-list")} />
      </div>
    </main>
  );
}

export function WaitingList({ onTimerEnd }) {
  const [params] = useSearchParams();
  const [name, setName] = useState(null);
  const [hideTimer, setHideTimer] = useState(true);
  const [timer, setTimer] = useState(null);
  const { data, error, loading } = useFetchWaitingList();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    extractSearchParams();

    // Set up a timer to navigate to the home screen after 5 seconds
    // const intervalId = setInterval(() => {
    //   setTimer((prevTimer) => prevTimer - 1);
    // }, 1000);

    // Clear the timer when the component is unmounted
    // return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   if (timer === 0) { onTimerEnd();
  //   }
  // }, [timer]);

  function extractSearchParams() {
    params.forEach((value, key) => {
      if (key === "name") {
        setShowModal(true);
        //setTimer(15);
        // setHideTimer(false);
        // setName(value || "Patient Name");
      }
    });
  }

  const handleTimerEnd = () => {
    setShowModal(false);
    onTimerEnd();
  }

  // Split business name into words
  if (loading) return <p>Loading...</p>;
  if (!data) return <p>No Data Found</p>;
  if (!data.business) return <p>No Business Found</p>;
  if (!data.waitingList) return <p>No Waiting List Found</p>;

  const words = data.business.name.split(" ");
  const firstWord = words.shift(); // Remove the first word
  const restOfWords = words.join(" "); // Join the remaining words
  const currentDate = new Date();
  const dayNumber = currentDate.getDate();
  const daySuffix = getDaySuffix(dayNumber);
  const month = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(currentDate);
  const day = currentDate.toLocaleString("default", { weekday: "long" }); // E.g., "Tue"

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) return "th";
    const lastDigit = day % 10;
    if (lastDigit === 1) return "st";
    if (lastDigit === 2) return "nd";
    if (lastDigit === 3) return "rd";
    return "th";
  }
  return (
    <>
      {
        showModal &&
        <div className={classes.modal}>
          <div className={classes.modal_content}>
            <h2>{words}</h2>
            <p>Thank you for joining the waiting list. If you are not here when we call your name. We will go to the next person in line.</p>
            <button onClick={() => handleTimerEnd()}>ok</button>
          </div>
        </div>
      }
      {/* {!hideTimer && timer && <p className={classes.timer}>{timer}s</p>} */}
      {/* <div className={classes.date_header}>
        <p>
          {moment().format(data?.business?.dateFormat || DEFAULT_DATE_FORMAT)}
        </p>
        <p>
          {moment().format(data?.business?.timeFormat || DEFAULT_TIME_FORMAT)}
        </p>
      </div> */}
      <br />
        <div className={classes.businessName}>
          <span className={classes.firstWord}>{firstWord}</span>
          <span className={classes.restOfWords}>{restOfWords}</span>
        </div>
     
      <h4 className={classes.h4}>Waiting list</h4>

      <div className={classes.waiting_table_cont}>
        <table className={classes.waiting_table}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Party</th>
              <th>Phone</th>
              <th>Time In</th>
            </tr>
          </thead>
          <tbody>
            {data?.waitingList?.map((acc, idx) => (
              <tr
                key={idx}
                className={idx === 0 ? classes.upNextRow : null} // Highlight the first row
              >
                <td>{acc.no}</td>
                <td>{acc.initial}</td>
                <td>{acc.party}</td>
                <td className="centered">{acc.phone.replace(/\D/g, '')}</td>
                <td>
                  <span className={classes.timeSpan}>
                    {moment(acc.createdAt).format(data?.business?.timeFormat || DEFAULT_TIME_FORMAT)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>



      </div>

      {/* Bottom Two Buttons */}
      <div className={classes.footer}>
        <div className={classes.next_btn}>
          <button onClick={() => navigate("/share")}>Share</button>
          <button onClick={() => navigate("/")}>Keypad</button>
        </div>
      </div>
    </>
  );
}
