import { useState, useEffect } from "react";
import classes from "./dial-pad.module.css";
import moment from "moment";
import axios from "axios";
import { HTTP_STATUS } from "../../../constants/http-status";
const { DateTime } = require("luxon");


import { useNavigate } from "react-router-dom";
// import { validateNumbeByCountry } from "../../utils/validate-num-by-country";
import { errorToast } from "../../../utils/notifications";
import { IconShare } from "@tabler/icons-react";
import { IoTimerOutline } from "react-icons/io5";
import { useFetchPrevVisit } from "../../../hooks/use-fetch-visit";
import { getBaseUrl } from "../../../constants/base-url";


export function DialPadOriginal({ business, error, timeout}) {
  const [val, setVal] = useState("");
  const today = new Date().toISOString().split("T")[0];

  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [visit, setVisit] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const keywords = ["Time-Out", "Time Out", "Sign Out"];
  const [fieldsExist, setFieldsExist] = useState({});
  const [isSignin, setIsSignedIn] = useState(false);
  useEffect(() => {
    if(localStorage.getItem('signin')) {
      if (localStorage.getItem('signin') == 1) {
          setIsSignedIn(true);
      }
      else {
        setIsSignedIn(false);

      }
    }
    else {
      setIsSignedIn(false);

    }
  },[localStorage])


  useEffect(() => {
    // localStorage.remove("visit");
    const storedVisit = localStorage.getItem("visit");
    if (storedVisit) {
      setVisit(JSON.parse(storedVisit)); 
      setIsLoggedIn(true);
    } else {
      console.log("No visit data found in localStorage.");
    }


    const exists = keywords.some(keyword => 
      business?.fields.some(item => item.title.toLowerCase() === keyword.toLowerCase())
    );

    setFieldsExist(exists);

    if(exists) {
      if(localStorage.getItem('signin')) {
        if(localStorage.getItem('signin') == 1) {
           
        }

      }
    }

    

  }, [localStorage, business]);  


  
  function getDate(timestamp) {
    const dateOnly = timestamp.split("T")[0];
    return dateOnly;  
  }
  



  async function verifyVisit() {
      if(fieldsExist) {
          try {
            const res = await axios.get(`${getBaseUrl()}/pwa/visit/${val}`, {
            params: {
              businessId: business._id,
            },
          });
    
        
          if (res.status === HTTP_STATUS.OK) {
            if(!res.data.visit.timeout) {
              
              if(getDate(res.data.visit.createdAt) == today) {
                setVisit(res.data);  
                setIsLoggedIn(true);
                localStorage.setItem('visit', JSON.stringify(res.data));  
              }
              else {
                navigate(`/name-form/${val}`);
    
              }
    
            
              
            }
            else {
              navigate(`/name-form/${val}`);
    
            }
          
    
          } else {
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.error("Visit not found. Redirecting to name-form.");
            navigate(`/name-form/${val}`);
          } else {
            console.error("An error occurred while fetching the visit:", error);
            // Optionally, show a message to the user
          }
        }

      }
      else {
        navigate(`/name-form/${val}`);

      }
      
  
  }

  async function updateVisit() {
   
    try {
      const isoTimestamp = DateTime.now().setZone(business?.timeZone).toISO();
      console.log(isoTimestamp);
      const resp = await axios.put(
        `${getBaseUrl()}/pwa/update-visit/${visit.visit.waitingId}`, 
        {
          timeout:isoTimestamp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(resp);

      if(resp) {
        
        if(localStorage.getItem("visit")) {
          localStorage.removeItem("visit");
        }

        setIsLoggedIn(false);
        setVisit(null);
        setVal();
        
        navigate("/");

        
      }
    } catch (error) {

      console.log(error);
    }

  }
  function handleBtnClick(num) {
    const currentVal = val || ""; 
    const newVal = currentVal.toString() + num.toString(); 

    if (newVal.length > 10) return; 

    setVal(newVal);
    setDisabled(newVal.length < 4);
  
  }

  function handleBackClick() {
    if (!val) return;
    const newVal = val.slice(0, -1);

    if (newVal.length >= 5) setDisabled(false);
    else setDisabled(true);

    setVal(newVal);
  }

  function handleClearClick() {
    setDisabled(true);
    setVal("");
  }

  function handleKeyDown(event) {
    // Get the pressed key code
    const keyCode = event.which || event.keyCode;

    // Allow only numbers (0-9) and the backspace key (8)
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) return;

    let newVal;
    const keyString = String.fromCharCode(keyCode);

    // Backspace Clicked
    if (keyCode === 8) newVal = val.slice(0, -1);
    else newVal = val + keyString;

    if (newVal.length > 10) return;
    setVal(newVal);
    if (newVal.length >= 4) setDisabled(false);
    else setDisabled(true);
  }

  function handleNextClick() {
   
    if (val.length !== 10) return errorToast("Invalid Number");
    verifyVisit();   

   
  }

  function handleMsgClick() {
    const num = localStorage.getItem("number");
    if (!num) return errorToast("No number found please login first");
    navigate(`/chat?number=${num}`);
  }

  if (error) return <p>{error}</p>;
  if (!business || !business.name) {
    return <p>No Business Found, Kindly Open the full Link again</p>;
  }

  // Split business name into words
  
  const words = business.name.split(" ");
  const firstWord = words.shift(); // Remove the first word
  const restOfWords = words.join(" "); // Join the remaining words
  const image = business?.businessProfile;

  const currentDate = new Date();
  const dayNumber = currentDate.getDate();
  const daySuffix = getDaySuffix(dayNumber);
  const month = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(currentDate);
  const day = currentDate.toLocaleString("default", { weekday: "long" }); // E.g., "Tue"

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) return "th";
    const lastDigit = day % 10;
    if (lastDigit === 1) return "st";
    if (lastDigit === 2) return "nd";
    if (lastDigit === 3) return "rd";
    return "th";
  }

  function getTimeDifference(visitDate) {
    const createdAt = new Date(visitDate);
    
    const now = new Date();
    
    const diffInMs = now - createdAt;
    
    const diffInSec = Math.floor(diffInMs / 1000);
    const hours = Math.floor(diffInSec / 3600);
    const minutes = Math.floor((diffInSec % 3600) / 60);
    const seconds = diffInSec % 60;
  
    return `${hours}h : ${minutes}m : ${seconds}s`;
  }
  
  function updateTime() {
    
    document.getElementById("time-difference").setAttribute("style", "font-size: 18pt !important; font-weight: bold !important;");
    document.getElementById("time-difference").textContent = getTimeDifference(visit.visit?.createdAt);
  }

  function handleCloseModal() {
    localStorage.removeItem('signin');
    setIsSignedIn(false);
  }

  useEffect(() => {
    if (isLoggedIn) {
      const intervalId = setInterval(updateTime, 1000);


      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn]);
  

  return (
    
    <div className={classes.body_wrapper}> 
      {fieldsExist && isSignin && (
         <div className={classes.modal}>
            <div className={classes.modal_content}>
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                Please enter your phone number prior to leaving to log time out.
              </p>
              <button onClick={() => handleCloseModal()}>ok</button>
            </div>
        </div>
      )}
      <div className={classes.container}>
        {/* Header */}
        <div className={classes.date_time}>{month}
          {image && <img className={classes.imageUser} src={image} />}
          <div className={classes.day}>{day}</div>
        

          
        </div>

        {/* Title */}
        <div className={classes.heading}>
          <div className={classes.heading}>
            {firstWord} <span>{restOfWords}</span>
          </div>
        </div>
        <div className={classes.content}>
          {/* Dial Pad */}

          <div className={classes.log}>
            
            <div className={classes.input_sec}>
              {/* {timeout?.timeout && !isLoggedIn && (
                <>
                 Please enter your phone number prior to leaving to log time out.  
                </>
              )} */}
  <>
                 Please enter your phone number prior to leaving to log time out.  
                </>
              {isLoggedIn && (
                <>
                  <div className={classes.visitor_container}>
                    <span>
                      Hello, <b>{visit?.visit?.name} </b>
                    </span>
                    <span>
                      <div id="time-difference">Loading...</div>

                    </span>
                    
                  </div>
                </>
              )}
              {!isLoggedIn && (
                <div className={classes.dial_nums_input}>
          
                  <input
                    className={classes.num_input}
                    value={val}
                    onKeyDown={handleKeyDown}
                  />
              </div>

              )}
              
            </div>
         
            {!isLoggedIn && (
              <div className={classes.dial_nums}>
              <div className={classes.row}>
                <Btn onClick={handleBtnClick}>1</Btn>
                <Btn onClick={handleBtnClick}>2</Btn>
                <Btn onClick={handleBtnClick}>3</Btn>
              </div>
              <div className={classes.row}>
                <Btn onClick={handleBtnClick}>4</Btn>
                <Btn onClick={handleBtnClick}>5</Btn>
                <Btn onClick={handleBtnClick}>6</Btn>
              </div>
              <div className={classes.row}>
                <Btn onClick={handleBtnClick}>7</Btn>
                <Btn onClick={handleBtnClick}>8</Btn>
                <Btn onClick={handleBtnClick}>9</Btn>
              </div>
              <div className={classes.row}>
              
                <Btn onClick={handleBackClick} className={classes.back_btn}>Back</Btn>
                <Btn onClick={handleBtnClick}>0</Btn>
                <Btn onClick={handleClearClick} className={classes.clear_btn}>Clear</Btn>
              </div>
            </div>
            
            
            )}
            
            {/* buttons */}
            <div className={classes.button_sec}>
              <button 
                    onClick={() => navigate("/share")}
                    className={classes.share_btn}
                    style={{ margin: business.hideWaitingList ? "0 auto" : "" }} // Dynamically center
                  >
                    <IconShare color="#ff8120" size={24} />
              </button>
              <div className={classes.next_btn}>
                {!timeout?.timeout  ? (
                  <>
                    <button
                      onClick={isLoggedIn ? updateVisit : handleNextClick}
                      style={{
                        cursor: "pointer",
                        backgroundColor: disabled ? "#ff8120" : "#ff8120",
                        //color: disabled ? "#1d1d1d" : "#fff",
                        color: "#fff",
                        padding: "10px 20px",
                      }}
                    >
                      {isLoggedIn ? 'Time Out' : 'Next'}
                    </button>
                  
                  </>
                ) : (
                  <>
                   <button
                      onClick={!isLoggedIn ? verifyVisit : updateVisit }
                      style={{
                        cursor: "pointer",
                        backgroundColor: disabled ? "#ff8120" : "#ff8120",
                        //color: disabled ? "#1d1d1d" : "#fff",
                        color: "#fff",
                        padding: "10px 20px",
                      }}
                    >
                      {isLoggedIn ? 'Time Out' : 'Log In'}
                    </button>
                  </>
                )}
                
              </div>
                {!business.hideWaitingList ? (
                    <button onClick={() => navigate("/waiting-list")} className = {classes.waitinglist_btn}>
                      <IoTimerOutline size={24} color="#ff8120" />
                    </button>
                  ) : null} 

                {/* <IconMessage size={15} onClick={handleMsgClick} /> */}
            </div>

              <div className={classes.footer}>
                  
                {/* Remove empty button */}
                {/* <button onClick={() => navigate("/share")}>Share</button> */}
                {/* {!business.hideWaitingList && (
                <button onClick={() => navigate("/waiting-list")}>
                  Waiting List
                </button>
                )} */}
              </div>
      </div>
    </div>

        {/* 
        ---------------------------
            Bottom Two Buttons
        ---------------------------
        */}
        
      </div>
    </div>
  );
}

function Btn({ children, onClick }) {
  function handleClick() {
    onClick(children);
  }

  return (
    <button onClick={handleClick} className={classes.dial_btn}>
      {children}
    </button>
  );
}
