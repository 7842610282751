import QRCode from "react-qr-code";
import { getBaseUrl, getPwaUrl } from "../../constants/base-url";
import { QR } from "./qr";
import classes from "./share-panel-new.module.css";
import { useRef, useState } from "react";
import { useClipboard } from "../../hooks/use-clipboard";
import { errorToast, successToast } from "../../utils/notifications";
import { isValidEmail } from "../../utils/validation";
import axios from "axios";
import { HTTP_STATUS } from "../../constants/http-status";
import { getMsgFromAxiosErr } from "../../utils/get-axios-err-msg";
import { useNavigate } from "react-router-dom";
import * as htmlToImage from "html-to-image";


export function SharePanelNew({ business }) {
  const [showQr, setShowQr] = useState(false);
  const pwaLoginLink = `${getPwaUrl()}/${business?._id}`;
  const clipboard = useClipboard({ timeout: 1000 });
  const [sending, setSending] = useState(false);

  const navigate = useNavigate();
  const emailRef = useRef(null);
  const qrRef = useRef(null);

  function copyLink() {
    clipboard.copy(pwaLoginLink);
    successToast("Link copied to clipboard");
  }

  if (showQr) {
    return <QR business={business} onClose={() => setShowQr(false)} />;
  }

  async function sendLink() {
    try {
      const email = emailRef.current?.value;
      if (!email) return errorToast("Email is required");
      if (isValidEmail(email) === false) {
        return errorToast("Please enter a valid email");
      }

      setSending(true);
      const res = await axios.post(`${getBaseUrl()}/pwa/send-link`, {
        email,
        businessId: business._id,
      });
      setSending(false);

      if (res.status !== HTTP_STATUS.OK) {
        return errorToast(res.data?.msg || "Something went wrong");
      }

      emailRef.current.value = null;
      successToast("Link sent successfully");
    } catch (error) {
      setSending(false);
      const msg = getMsgFromAxiosErr(error);
      errorToast(msg);
    }
  }

  async function downloadQr() {
    try {
      const svg = qrRef.current; // Use getCanvas method to get the canvas element

      // Convert the SVG to a data URL
      const svgDataUrl = await htmlToImage.toPng(svg);

      const a = document.createElement("a");
      a.href = svgDataUrl;
      a.download = "signinlink.png";

      // Trigger a click event on the link
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      successToast("QR Saved!");
    } catch (error) {
      errorToast("Unable to save QR");
    }
  }

  return (
    <div className={classes.cont}>
      <div className={classes.x_header}>
        <button onClick={() => navigate(-1)} className={classes.close_btn}>
          <img style={{ height: "1rem", width: "1rem" }} src="/x.svg" />
        </button>
      </div>

      <h4 className={classes.email}>
        Share your{" "}
        <img src="/text-logo.svg" style={{ height: 25, marginBottom: -5 }} />
      </h4>
      <input
        placeholder="Email Address"
        className={classes.email_input}
        ref={emailRef}
      />
      <button
        className={classes.send_btn}
        onClick={sendLink}
        disabled={sending}
      >
        Send
      </button>

      {/* <a className={classes.scan_qr_txt}>Scan QR</a> */}
      <div className={classes.qr_cont}>
        <div ref={qrRef} className={classes.qr_cont_center}>
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={pwaLoginLink}
          />
          <img
            src="/share/qr-logo.svg"
            style={{
              borderRadius: 5,
              position: "absolute",
              width: 40,
              height: 40,
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
        </div>
      </div>

      <div className={classes.flex_row_center}>
        <a className={classes.copy_btn_txt} onClick={copyLink}>
          <span>
            <img src="/link.svg" style={{ width: 20, marginBottom: -5 }} />
          </span>
          Copy Link
        </a>

        <a className={classes.copy_btn_txt} onClick={downloadQr}>
          <span>
            <img
              src="/share/download-btn.svg"
              style={{ width: 20, marginBottom: -3 }}
            />
          </span>
          Download
        </a>
      </div>

    

      {/* Bottom Two Buttons */}
      {/* <div className={classes.log}>
        <button onClick={() => navigate("/")}>Keypad</button>
        <button onClick={() => navigate("/waiting-list")}>Waiting List</button>
      </div> */}
    </div>
  );
}
