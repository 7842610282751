import axios from "axios";
import { useEffect, useState } from "react";
import { HTTP_STATUS } from "../constants/http-status";
import { getMsgFromAxiosErr } from "../utils/get-axios-err-msg";
import { getBaseUrl } from "../constants/base-url";
import moment from "moment";

export function useFetchWaitingList() {
  const [data, setData] = useState(null);
  const [error, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

  async function callApiAsync() {
    try {
      const ID = getId();

      if (!ID) {
        setLoading(false);
        return setErr("No Business found, Kindly open the full link again");
      }

      const res = await axios.get(`${getBaseUrl()}/pwa/waiting-list/${ID}`, {
        params: {
          today: moment().format("DD-MM-YY"),
        },
      });
      setLoading(false);

      if (res.status !== HTTP_STATUS.OK) {
        setErr(res?.data?.msg || res?.data?.message || "Something wen't wrong");
      } else {
        setData(res.data);
        saveIdInStorage(ID);
      }
    } catch (error) {
      setLoading(false);
      const msg = getMsgFromAxiosErr(error);
      setErr(msg);
    }
  }

  function getId() {
    try {
      const id = window?.localStorage?.getItem("businessId");
      let res;
      if (id) res = JSON.parse(id);
      else res = id;

      return res;
    } catch (error) {
      console.log("Opps!");
    }
  }

  useEffect(() => {
    callApiAsync(); // Fetch data initially

    const interval = setInterval(() => {
      callApiAsync(); // Fetch data periodically
    }, 5000); // Adjust the interval as needed

    return () => clearInterval(interval); 
  },[]);

  console.log("Data:", data);

  return { data, error, loading };
}
