import { useState } from "react";
import classes from "./time-input-modal.module.css";
import moment from "moment";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export function TimeInputModal({ timeFormat, onSave, defaultValue, onClose }) {
  const initState = {
    hh: defaultValue
      ? moment(defaultValue, timeFormat).format("hh")
      : moment().format("hh"),
    mm: defaultValue
      ? moment(defaultValue, timeFormat).format("mm")
      : moment().format("mm"),
  };


  const [time, setTime] = useState();
  const [ampm, setAmpm] = useState(
    defaultValue ? moment(defaultValue, timeFormat).format("A") : "AM"
  );

  const handleTimeChange = (newValue) => {
    setTime(dayjs(newValue).format('hh:mm'));
    setAmpm(dayjs(newValue).format('A'));
  };

  function handleSave(e) {
    const res = initState.hh + ":" + initState.mm + " " + ampm;
    const formattedTime = moment(time ? time + " " + ampm  : res, "hh:mm A").format(timeFormat);
    console.log(formattedTime);
    onSave(formattedTime);
  }

  const theme = createTheme({
  palette: {
    primary: {
      main: '#ff8120', // replace with your color
    },
  },
});

  return (
    <div className={classes.cont}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticTimePicker orientation="portrait"
                          onChange={handleTimeChange}
                          onAccept={() => {
                            onClose(); 
                            handleSave()
                          }}
                      
               />
            </LocalizationProvider>
          </ThemeProvider>
  </div>
  );
}
