import { useParams } from "react-router-dom";
import classes from "./home.module.css";
import { DialPad } from "../../components/dial-pad/dial-pad";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import moment from "moment";

export function HomePage() {
  const { businessId } = useParams();
  const { data, error, loading } = useFetchBusiness(businessId);

  let firstWord = "";
  let restOfWords = "";

  if (data?.name) {
    const words = data.name.split(" ");
    firstWord = words.shift(); // Remove the first word
    restOfWords = words.join(" "); // Join the remaining words
  }

  console.log("Data:", data);
  console.log("First Word:", firstWord);
  console.log("Rest of Words:", restOfWords);

  if (error) return <p>{error}</p>;

  return (
    <main className={classes.main}>
        {loading ? <p>Loading...</p> : 
        <div>
          <DialPad business={data} />
        </div>
        }
    </main>
  );
}